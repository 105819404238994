import "./header.scss";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/svg-icon/logo.svg";
import React, { useEffect, useState } from "react";
import chemicals from "../../assets/svg-image/Rectangle 70.png";
import chemicals_2 from "../../assets/svg-image/Rectangle 71.png";
import polygon from "../../assets/svg-image/Polygon 1.svg";
import { useSelector } from "react-redux";

const Header = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const productListData = useSelector(
    (state) => state.productListReducer.productListData
  );

  

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  
  const [ismobilemenuOpen, setIsmobilemenuOpen] = useState(false);

  const closeHamIndustry = () => {
    setIsmobilemenuOpen(false);
    setIsHovered(false);
  };

  // console.log(ismobilemenuOpen,'ismobilemenuOpen')
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const pathname = useLocation().pathname;

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[pathname])

  var isMobile;

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (screenWidth <= 992) {
    isMobile = true;
  } else {
    isMobile = false;
  }

  return (
    <div className="main-head">
      {isMobile ? (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid header_box customeHeaderAnimation">
            <Link className="navbar-brand" to="#">
              <img src={logo} alt="logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              onClick={()=>{setIsmobilemenuOpen(!ismobilemenuOpen)}}
              data-bs-toggle={ismobilemenuOpen && "collapse"}
              // data-bs-target="#navbarNav"
              // aria-controls="navbarNav"
              // aria-expanded="false"
              // aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={ismobilemenuOpen ? "collapse show navbar-collapse " : "collapse navbar-collapse"}  id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item" onClick={()=>{setIsmobilemenuOpen(false)}} >
                  <Link className="nav-link active" aria-current="page" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item" onClick={()=>{setIsmobilemenuOpen(false)}} >
                  <Link
                    className="nav-link"
                    to="/about"
                    role="button"
                  >
                    About Us
                  </Link>
                </li>
                <li className="nav-item dropdown" >
                  <Link
                    className="nav-link dropdown-toggle"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Industries
                  </Link>
                  <ul
                    className={`dropdown-menu border-0 px-0 mx-0 ${
                      isHovered ? "show" : ""
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="row submenu-box">
                      <Link className="inds-header">Industries</Link>
                      <hr />
                      <div className="col-md-4">
                        {/* <ul>
                          <li>
                            <Link to="/personal-care">Personal Care</Link>
                          </li>

                          <li>
                            <Link to="/home-care">Home Care</Link>
                          </li>

                          <li>
                            <Link to="/food-nutrition">Food and Nutrition</Link>
                          </li>

                          <li>
                            <Link to="/water-treatment">Water Treatment</Link>
                          </li>

                          <li>
                            <Link to="/feed-fertilizer">
                              Feed and Fertilizer
                            </Link>
                          </li>
                          <li>
                            <Link to="/flavours-fragnances">
                              Flavours and Fragrances
                            </Link>
                          </li>
                        </ul> */}
                            <ul>
                          {productListData &&
                            productListData.slice(0, 6).map((v, i) => {
                              return (
                                <li key={i}>
                                  <Link to={`/product/${v.pc_id}`} onClick={()=> {closeHamIndustry()}}>
                                    {v?.pc_name}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                      <div className="col-md-4">
                        {/* <ul>
                          <li>
                            <Link to="/plastic-rubber">Plastic & Rubber</Link>
                          </li>
                          <li>
                            <Link to="/chemical-intermediate">
                              Chemical Intermediates
                            </Link>
                          </li>
                          <li>
                            <Link to="/paint-inks">Paints and Inks</Link>
                          </li>
                        </ul> */}
                        <ul>
                          {productListData &&
                            productListData.slice(6, 13).map((v, i) => {
                              return (
                                <li key={i}>
                                  <Link to={`/product/${v.pc_id}`} onClick={()=> {closeHamIndustry()}}>
                                    {v?.pc_name}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                      <div className="col-md-4  submenu-image-box">
                        <div>
                          <img src={chemicals} alt="" />
                        </div>
                        <div>
                          <img src={chemicals_2} alt="" />
                        </div>
                      </div>
                    </div>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/contact-us"
                    style={{
                      color: pathname === "/contact-us" ? "#003F7C" : "#00ABC8",
                    }} onClick={()=>{setIsmobilemenuOpen(false)}}
                  >
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      ) : (
        <nav className="navbar navbar-expand-lg header-cont">
          <div className="container header_box">
            <Link className="navbar-brand" to="/">
              <img src={logo} height={"60px"} alt="" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse d-flex flex-row-reverse carousel"
              id="navbarNav"
            >
              <ul className="navbar-nav ">
                <li className="nav-item home">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    to="/"
                    style={{ color: pathname === "/" ? "#003F7C" : "#000" }}
                  >
                    Home
                  </Link>
                </li>
                <li className=" about nav-item dropdown drop-box ">
                  <Link
                    className="nav-link"
                    to="/about"
                    style={{
                      color: pathname === "/about" ? "#00ABC8" : "#000",
                    }}
                  >
                    About us
                  </Link>
                </li>
                <li className="nav-item dropdown drop-box">
                  <Link
                    className="nav-link dropdown-toggle"
                    role="button"
                    aria-expanded="false"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      color: pathname === "/personal-care" ? "#000" : "#000",
                      fontWeight: "bold",
                    }}
                  >
                    Industries
                  </Link>
                  <ul
                    className="dropdown-menu border-0  px-0 mx-0"
                    style={{ display: isHovered ? "block" : "none" }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="row submenu-box">
                      <div className="polygon">
                        <img src={polygon} alt="polygon" />
                      </div>
                      <Link className="inds-header">Industries</Link>
                      <hr />
                      <div className="col-md-4">
                        <ul>
                          {productListData &&
                            productListData.slice(0, 6).map((v, i) => {
                              return (
                                <li key={i}>
                                  <Link to={`/product/${v.pc_id}`}>
                                    {v?.pc_name}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                      <div className="col-md-4">
                        <ul>
                          {productListData &&
                            productListData.slice(6, 13).map((v, i) => {
                              return (
                                <li key={i}>
                                  <Link to={`/product/${v.pc_id}`}>
                                    {v?.pc_name}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                      <div className="col-md-4  ">
                        <div>
                          <img src={chemicals} alt="" />
                        </div>
                        <div>
                          <img src={chemicals_2} alt="" />
                        </div>
                      </div>
                    </div>
                  </ul>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/contact-us"
                    style={{
                      color: pathname === "/contact-us" ? "#003F7C" : "#00ABC8",
                    }}
                  >
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
    </div>
  );
};

export default Header;
