import "./App.css";
import Privacy from "./pages/privacy";
import "bootstrap/dist/js/bootstrap.js";
import Header from "./components/Header";
import Footer from "./components/footer";
import Loader from "./components/Loader";
import "bootstrap/dist/css/bootstrap.css";
import TermsAndConditions from "./pages/termsAndConditions";
import React, { Suspense, useState, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import {
  aboutPage,
  chemicalIntermediatePage,
  chemicalIntermediateWithSlug,
  contactUsPage,
  dashBoardPage,
  feedFertilizerPage,
  feedFertilizerWithSlug,
  flavourFragnancePage,
  flavourFragnanceWithSlug,
  foodNutritionPage,
  foodNutritionWithSlug,
  homeCarePage,
  homeCareWithSlug,
  notFoundPage,
  paintInksPage,
  paintInksWithSlug,
  personalCarePage,
  personalCarePageWithSlug,
  plasticRubberPage,
  plasticRubberWithSlug,
  privacyPage,
  termsAndConditionsPage,
  waterTreatmentPage,
  waterTreatmentWithSlug,
  careerPage
} from "./routes";
import Index from "./components/Error";
import Careers from "./components/Careers/Careers";
import { useDispatch } from "react-redux";
import { PRODUCT_LIST_FILE } from "./saga/constant/index";

const ContactPage = React.lazy(() => import("./pages/contactPage"));
const AboutUsPage = React.lazy(() => import("./pages/aboutUsPage"));
const IndustryPage = React.lazy(() => import("./pages/industryPage"));
const DashBoardPage = React.lazy(() => import("./screen/DashBoardPage"));
const IndustryDeatilsPage = React.lazy(() => import("./pages/subIndustries"));

function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => clearTimeout(delay);
  }, []);

  useEffect(() => {
    dispatch({ type: PRODUCT_LIST_FILE });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        {<Header />}

        <Suspense fallback={<Loader />}>
          <Routes>
            <Route exact path={notFoundPage} element={<Index />} />
             <Route exact path={careerPage}  element={<Careers/>} />

            {loading && (
              <Route
                exact
                path={`${dashBoardPage}|${contactUsPage}|${aboutPage}|${personalCarePage}`}
                element={<Loader />}
              />
            )}

            <Route exact path={dashBoardPage} element={<DashBoardPage />} />

            <Route exact path={contactUsPage} element={<ContactPage />} />

            <Route exact path={aboutPage} element={<AboutUsPage />} />

            <Route exact path={personalCarePage} element={<IndustryPage />} />
            <Route
              exact
              path={personalCarePageWithSlug}
              element={<IndustryDeatilsPage />}
            />

            <Route exact path={homeCarePage} element={<IndustryPage />} />
            <Route
              exact
              path={homeCareWithSlug}
              element={<IndustryDeatilsPage />}
            />

            <Route exact path={foodNutritionPage} element={<IndustryPage />} />
            <Route
              exact
              path={foodNutritionWithSlug}
              element={<IndustryDeatilsPage />}
            />

            <Route exact path={waterTreatmentPage} element={<IndustryPage />} />
            <Route
              exact
              path={waterTreatmentWithSlug}
              element={<IndustryDeatilsPage />}
            />

            <Route exact path={feedFertilizerPage} element={<IndustryPage />} />
            <Route
              exact
              path={feedFertilizerWithSlug}
              element={<IndustryDeatilsPage />}
            />
            <Route
              exact
              path={flavourFragnancePage}
              element={<IndustryPage />}
            />
            <Route
              exact
              path={flavourFragnanceWithSlug}
              element={<IndustryDeatilsPage />}
            />

            <Route exact path={plasticRubberPage} element={<IndustryPage />} />
            <Route
              exact
              path={plasticRubberWithSlug}
              element={<IndustryDeatilsPage />}
            />

            <Route
              exact
              path={chemicalIntermediatePage}
              element={<IndustryPage />}
            />
            <Route
              exact
              path={chemicalIntermediateWithSlug}
              element={<IndustryDeatilsPage />}
            />
            <Route exact path={paintInksPage} element={<IndustryPage />} />
            <Route
              exact
              path={paintInksWithSlug}
              element={<IndustryDeatilsPage />}
            />

            <Route
              exact
              path={termsAndConditionsPage}
              element={<TermsAndConditions />}
            />

            <Route exact path={privacyPage} element={<Privacy />} />
          </Routes>
        </Suspense>

        <Footer />
      </Router>
    </>
  );
}

export default App;
