import React from "react";
import "./privacy.scss";
import RectangleGrid from "../../components/rectangleGrid";

const Privacy = () => {
  return (
    <>
      <div className="main-privacy">
        <div className="privacy-banner">
          <div className="privacy-banner-content">
            <div className="privacy-banner-title">
              <h2>Privacy Policy</h2>
              <span>Home {">>"} Privacy Policy</span>
            </div>
          </div>
        </div>
        <RectangleGrid />
        <div className="row privacy-whole-content">
          <div className="col-md-6 privacy-content">
            <div className="privacy-content-title">
              We Care About your Privacy
            </div>
            <div className="privacy-content-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              occaecati cupiditate non provident, similique sunt in culpa qui
              officia deserunt mollitia animi, id est laborum et dolorum fuga.
              Et harum quidem rerum facilis est et expedita distinctio. Nam
              libero tempore, cum soluta nobis est eligendi optio cumque nihil
              impedit quo minus id quod maxime placeat facere possimus, omnis
              voluptas assumenda est, omnis dolor repellendus. Temporibus autem
              quibusdam et aut officiis debitis.
            </div>
            <div className="privacy-button">
              <button className="privacy-button-read"> Read More</button>
            </div>
          </div>
          <div className="col-md-6 privacy-content-image"></div>
        </div>
        <div className="privacy-info">
          <div className="privacy-info-title">
            What Information Do we Collect ?
          </div>
          <div className="privacy-info-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
            occaecati cupiditate non provident, similique sunt in culpa qui
            officia deserunt mollitia animi, adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. occaecati
            cupiditate non provident, similique sunt in culpa
          </div>
          <div className="privacy-info-clause">
            <ul>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                occaecati cupiditate non provident, similique{" "}
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                occaecati cupiditate non provident, similique{" "}
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                occaecati cupiditate non provident, similique{" "}
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                occaecati cupiditate non provident, similique{" "}
              </li>
            </ul>
          </div>
        </div>
        <div className="privacy-info-use">
          <div className="privacy-info-use-title">
            How Do we use your Information ?
          </div>
          <div className="privacy-info-use-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
            occaecati cupiditate non provident, similique sunt in culpa qui
            officia deserunt mollitia animi, adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. occaecati
            cupiditate non provident, similique sunt in culpa
          </div>
          <div className="privacy-info-use-clause">
            <ul>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                occaecati cupiditate non provident, similique{" "}
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                occaecati cupiditate non provident, similique{" "}
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                occaecati cupiditate non provident, similique{" "}
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                occaecati cupiditate non provident, similique{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
