import { apiUrl } from "../config";


export const URL = apiUrl;
export const BASE_URL = URL;
export const API_URL = {
  index: "/",
  GET_QUOTE: "get_quote",
  GET_IN_TOUCH: "get_in_touch",
  CONTACT_US: "contact_us",
  PRODUCT_LIST:"get_pc_list",
  PRODUCT_DETAILS:"get_product_list?id=",
  INDESTRY_LIST:"get_pc_list",
  SINGAL_PRODUCT_DETRAIL:"get_product_details?id=" 
};
