import {put,call,takeLatest} from "@redux-saga/core/effects"
import {FOOTER_INDESTRY_LIST_FILE} from "./constant/index"
import {FOOTER_INDESTRY_LIST_DATA} from "../redux/constant/index"
import axios from "../axios/index"
import  {BASE_URL,API_URL} from "../axios/config"

async function footerIndestryApi(data){
    return axios.request({
        method:"GET",
        url:BASE_URL+API_URL.INDESTRY_LIST,
        data
    })
}

 function* footerIndestryAction(action){
    let {payload,reject}=action
  try {
        let response= yield call(footerIndestryApi,payload)
        if (response.status===200) {
             yield put({type:FOOTER_INDESTRY_LIST_DATA,payload:response?.data?.data})
        }
    } catch (error) {
        if(reject)
           reject(error.message)
    }
}

 export function* footerIndestryData(){
      yield takeLatest(FOOTER_INDESTRY_LIST_FILE,footerIndestryAction)
 }