import React from 'react'
import './termsAndConditions.scss'
import RectangleGrid from '../../components/rectangleGrid'

const TermsAndConditions = () => {
  return (
    <>
      <div className='main-terms'>
        <div className='terms-banner'>
          <div className='terms-banner-content'>
            <div className='terms-banner-title'>
              <h2>Terms And Conditions</h2>
              <span>Home {">>"} Terms & Condition</span>
            </div>
          </div>
        </div>
        <RectangleGrid />
        <div className='row terms-whole-content'>
          <div className='col-md-6 terms-content'>
            <div className='terms-content-title'>
              Terms & Condition
            </div>
            <div className='terms-content-text'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis.
            </div>
            <div className='terms-button'>
              <button className='terms-button-read'> Read More</button>
            </div>
          </div>
          <div className='col-md-6 terms-content'>

          </div>
          <div className='terms-contract'>
            <div className='terms-contract-title'>
              Terms About Contract
            </div>
            <div className='terms-contract-text'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, similique sunt in culpa qui o.
            </div>
            <div className='terms-contract-clause'>
              <ul>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, similique </li>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, similique </li>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, similique </li>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, similique </li>
              </ul>
            </div>
          </div>
          <div className='terms-nda'>
            <div className='terms-nda-title'>
              About NDA
            </div>
            <div className='terms-nda-text'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, similique sunt in culpa qui o.
            </div>
            <div className='terms-nda-clause'>
              <ul>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, similique </li>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, similique </li>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, similique </li>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, similique </li>
                <li> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, </li>
              </ul>
            </div>
          </div>
          <div className='terms-law'>
            <div className='terms-law-title'>
              Act’s as per the law
            </div>
            <div className='terms-law-text'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, similique sunt in culpa qui o.
            </div>
            <div className='terms-law-clause'>
              <ul>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, similique </li>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, similique </li>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, similique </li>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. occaecati cupiditate non provident, similique </li>
              </ul>
            </div>
          </div>
          <div className='row terms-query'>
            <div className='col-md-6 terms-query-content'>
              <div className='terms-query-title'>
                Connect us Regarding any Query
              </div>
              <div className='terms-query-text'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
              </div>
              <div className='terms-query-form'>
              <div className='terms-query-details-number'>
                <div className='terms-query-details-name'>
                  Phone Number
                </div>
                <div className='terms-query-details-value'>
                  +91 32547 65102 / +91 54893 25102
                </div>
              </div>
              <div className='terms-query-details-email'>
                <div className='terms-query-details-name'>
                  Email Address
                </div>
                <div className='terms-query-details-value'>
                  Companymail@gmail.com
                </div>
              </div>
              <div className='terms-query-details-address'>
                <div className='terms-query-details-name'>
                  Office Address
                </div>
                <div className='terms-query-details-value'>
                  B/1002 Building Name , Near Landmark Name Abc Street
                  City Name State 354900
                </div>
              </div>
              </div>
            </div>
            <div className='col-md-6 terms-query-image'>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TermsAndConditions