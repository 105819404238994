
import { all } from "@redux-saga/core/effects";
import { contactFilesData } from "./contact"; 
import { quoteFilesData } from "./quote";
import { touchFilesData } from "./touch";
import {productListData} from "./productList"
import {productDetailData} from "./productDetail"
import {singalProductDetailData} from "./singalProductDetail"
import {footerIndestryData} from "./footerIndestry"


export default function* rootSaga() {
  yield all([
    contactFilesData(),
    quoteFilesData(),
    touchFilesData(),
    productListData(),
    productDetailData(),
    singalProductDetailData(),
    footerIndestryData()
  ]);
}

