import { call, put, takeLatest } from "@redux-saga/core/effects";
// import {useParams,useLocation} from "react-router-dom"
import axios from "../axios/index";
import { BASE_URL, API_URL } from "../axios/config";
import { SINGAL_PRODUCT_DETRAIL } from "./constant/index";
import { PRODUCT_DETRAIL } from "../redux/constant/index";

async function singalProductDetailApi(data) {
  return axios.request({
    method: "GET",
    url: BASE_URL + API_URL.SINGAL_PRODUCT_DETRAIL,
    params: data,
  });
}

function* sigalProductDetailAction(action) {
  let { payload, reject } = action;

  try {
    let response = yield call(singalProductDetailApi, payload);
     if (response.status === 200) {
      yield put({ type: PRODUCT_DETRAIL, payload: response?.data?.data });
    }
  } catch (error) {
    if (reject) reject(error);
  }
}

export function* singalProductDetailData() {
  yield takeLatest(SINGAL_PRODUCT_DETRAIL, sigalProductDetailAction);
}
