import {PRODUCT_LIST_DATA} from "../constant/index"

const initailState={
    productListData:null,
}

export default function productListReducer(state=initailState,action){

      switch (action.type){
        case PRODUCT_LIST_DATA:
            
            return{
                ...state,
                productListData:action?.payload
            }
       default :
         return state
      }
      
}

