import React from 'react'
import {Container,Button} from "reactstrap";
import "../Error/ErrorPage.scss"
import NotFoundimage from "../../assets/svg-image/404.png"
import {useNavigate} from "react-router-dom"
import {dashBoardPagePattern} from "../../routes"



const Index = () => {
    
const navigate=useNavigate()
const handleNavigate=()=>{
     navigate(dashBoardPagePattern())
}


  return (
    <>
       <Container>
          <div className='not_found_section'>
           <div className='not_found_image'>
           <img src={NotFoundimage} alt='Not_Found'/>
            </div>
            <div className='not_found_text'>
                <span>oops! Page Not Found </span>
            </div>
            <div className='not_found_button'>
               <Button className='button_text' onClick={()=>handleNavigate()}>Go back to home</Button>
            </div>
         </div>
    </Container>
        
    </>
  )
}

export default Index