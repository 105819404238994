import { call, put, takeLatest } from "@redux-saga/core/effects";
import axios from "../axios/index";
import { BASE_URL, API_URL } from "../axios/config";
import { PRODUCT_LIST_DATA } from "../redux/constant/index";
import { PRODUCT_LIST_FILE } from "./constant/index";

async function productListApi(data) {
  return axios.request({
    method: "GET",
    url: BASE_URL + API_URL.PRODUCT_LIST,
    data,
  });
}

function* productListAction(action) {
  let { payload, reject } = action;
  // console.log("i am action data",action.payload)
  try {
    let response = yield call(productListApi, payload);
    //  console.log("response",response);
    if (response.status === 200) {
      yield put({ type: PRODUCT_LIST_DATA, payload: response?.data?.data });
    }
  } catch (error) {
    if (reject) reject(error.message);
  }
}

export function* productListData() {
  yield takeLatest(PRODUCT_LIST_FILE, productListAction);
}
