import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { InfinitySpin } from "react-loader-spinner";
import "./loader.scss";

LoadingOverlay.propTypes = undefined;

export default function Loader({ isActive, ...props }) {
  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, "4000");

  useEffect(() => {
    if (isActive || loading)
      document.body.classList.add("loader-overflow-none");
    else document.body.classList.remove("loader-overflow-none");
  }, [isActive, loading]);

  return (
    <div className="loader-container">
      <LoadingOverlay active={isActive || loading} className="loading-overlay">
        {(isActive || loading) && (
          <div className="infinity-spin-loader">
            <InfinitySpin width="200" color="#00246B" />
          </div>
        )}

        {props.children}
      </LoadingOverlay>
    </div>
  );
}
