import { combineReducers } from "redux";

import contactReducer from "../reducer/contact";
import quoteReducer from "../reducer/quote";
import touchReducer from "../reducer/touch";
import productListReducer from "../reducer/productList"
import productDetailReducer from "../reducer/productDetails"
import singalProductDetailReducer from "../reducer/singalProductDetails"
import {footerIndestryReducer} from "../reducer/footerIndestry"



const rootReducer = combineReducers({
    contactReducer,
    quoteReducer,
    touchReducer,
    productListReducer,
    productDetailReducer,
    singalProductDetailReducer,
    footerIndestryReducer
});

export default rootReducer;
