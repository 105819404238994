import {PRODUCT_DETAILS_DATA} from "../constant/index"

const initalState={
    productDetailData:null
}

export default function productDetailReducer(state=initalState,action){

    switch (action.type){
        case PRODUCT_DETAILS_DATA:
            return{
             ...state,
             productDetailData:action?.payload
        }
        default:
            return state
    }
}