import './footer.scss'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import fb from "../../assets/svg-icon/facebook 1.svg"
import logo from "../../assets/svg-icon/Bhagy-logo 1 (1).svg"
import insta from "../../assets/svg-icon/instagram 1.svg"
import twitter from "../../assets/svg-icon/twitter-alt-circle 1.svg"
import linkedin from "../../assets/svg-icon/linkedin 1.svg"
import {useDispatch,useSelector} from "react-redux"
import {FOOTER_INDESTRY_LIST_FILE} from "../../saga/constant/index"
import {careerPagePattern} from "../../routes"



const Footer = () => {
    const current_year=new Date();
    const dispatch=useDispatch()
    const footerIndestryListData=useSelector((state)=>state.footerIndestryReducer)
        

    useEffect(()=>{
        dispatch({type:FOOTER_INDESTRY_LIST_FILE})
        // eslint-disable-next-line
    },[])

    
    return (
        <div>
          <div className="row footer-box mx-0">
                <div className="col-md-6 footer-left">
                    <div className="footer-image">
                        <div className="footer-logo mb-4">
                            <Link> <img src={logo} alt="logo" /></Link>
                        </div>
                        <div className="social-icon">
                            <Link to={"https://www.facebook.com/profile.php?id=61552054539725"}>  <img src={fb} alt="fb" /> </Link>
                            <Link to={"https://www.instagram.com/bhagychemicals"}>   <img src={insta} alt="insta" /></Link>
                            <Link to={"https://x.com/Bhagychemicals?t=bBMPxchsQeSSk2352DXQpw&s=08"}>  <img src={twitter} alt="twitter" /></Link>
                            <Link to={"https://www.linkedin.com/in/bhagy-chemicals-257619295"}> <img src={linkedin} alt="linkedin" /></Link>
                        </div>
                    </div>

                </div>
                <div className="col-md-6 footer-right">
                    <div className="row">
                        <div className="col-md-4 footer-about-box">
                            <span>About Us</span>
                            <ul>
                                <li><Link to={careerPagePattern()}>Careers</Link></li>
                                <li><Link to={careerPagePattern()}>Goals</Link></li>
                                <li><Link to={careerPagePattern()}>Company</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-4 footer-ind-box">
                            <span>Industries</span>
                            <ul>
                            {
                             footerIndestryListData?.indestryListData && footerIndestryListData.indestryListData.map((item)=>{
                                 return(
                                    <li key={item.pc_id}>
                                       <Link to={`/product/${item.pc_id}`}>{item.pc_name}</Link>
                                     </li>
                                         
                                 )
                                
                                })
                            }
                            </ul>

                        </div>
                        <div className="col-md-4 footer-media-box">
                            <span>Media</span>
                            <ul>
                                <li><Link to="/">Blogs</Link></li>
                                <li><Link to="/">News & Events</Link></li>
                                <li><Link to="/">Gallery</Link></li>
                            </ul>

                        </div>
                    </div>
                </div>
                <hr className='hr-footer'/>
                <div className='footer-bottom'>
                {current_year.getUTCFullYear()} Bhagy Chemicals Export Company all rights reserved
                </div>
            </div>
        </div>
    )
}

export default Footer