import { generatePath } from "react-router";

export const dashBoardPage = "/";
export const dashBoardPagePattern = () => {
  return generatePath(dashBoardPage);
};

export const contactUsPage = "/contact-us";
export const contactUsPagePattern = () => {
  return generatePath(contactUsPage);
};

export const aboutPage = "/about";
export const aboutPagePattern = () => {
  return generatePath(aboutPage);
};

 export const careerPage="/career";
 export const careerPagePattern=()=>{
  return generatePath(careerPage)
 }

//NOT FOUND PAGE
export const notFoundPage = "*";
export const notFoundPattern = () => {
  return generatePath(notFoundPage);
};

//INDUSTRY DETAILS PAGE ROUTES START
export const personalCarePage = "/product/:id";
export const personalCarePagePattern = () => {
  return generatePath(personalCarePage);
};
export const personalCarePageWithSlug = "/personal-care/:id";
export const personalCarePageWithSlugPattern = (id) => {
  return generatePath(personalCarePageWithSlug, id);
};

export const homeCarePage = "/home-care";
export const homeCarePattern = () => {
  return generatePath(homeCarePage);
};
export const homeCareWithSlug = "/home-care/:slug";
export const homeCareWithSlugPattern = (slug) => {
  return generatePath(homeCareWithSlug, slug);
};

export const foodNutritionPage = "/food-nutrition";
export const foodNutritionPattern = () => {
  return generatePath(foodNutritionPage);
};
export const foodNutritionWithSlug = "/food-nutrition/:slug";
export const foodNutritionWithSlugPattern = (slug) => {
  return generatePath(foodNutritionWithSlug, slug);
};

export const waterTreatmentPage = "/water-treatment";
export const waterTreatmentPattern = () => {
  return generatePath(waterTreatmentPage);
};
export const waterTreatmentWithSlug = "/water-treatment/:slug";
export const waterTreatmentWithSlugPattern = (slug) => {
  return generatePath(waterTreatmentWithSlug, slug);
};

export const feedFertilizerPage = "/feed-fertilizer";
export const feedFertilizerPattern = () => {
  return generatePath(feedFertilizerPage);
};
export const feedFertilizerWithSlug = "/feed-fertilizer/:slug";
export const feedFertilizerWithSlugPattern = (slug) => {
  return generatePath(feedFertilizerWithSlug, slug);
};

export const flavourFragnancePage = "/flavour-fragnance";
export const flavourFragnancePattern = () => {
  return generatePath(flavourFragnancePage);
};
export const flavourFragnanceWithSlug = "/flavour-fragnance/:slug";
export const flavourFragnanceWithSlugPattern = (slug) => {
  return generatePath(flavourFragnanceWithSlug, slug);
};

export const plasticRubberPage = "/plastic-rubber";
export const plasticRubberPattern = () => {
  return generatePath(plasticRubberPage);
};
export const plasticRubberWithSlug = "/plastic-rubber/:slug";
export const plasticRubberWithSlugPattern = (slug) => {
  return generatePath(plasticRubberWithSlug, slug);
};

export const chemicalIntermediatePage = "/chemical-intermediate";
export const chemicalIntermediatePattern = () => {
  return generatePath(chemicalIntermediatePage);
};
export const chemicalIntermediateWithSlug = "/chemical-intermediate/:slug";
export const chemicalIntermediateWithSlugPattern = (slug) => {
  return generatePath(chemicalIntermediateWithSlug, slug);
};

export const paintInksPage = "/paint-inks";
export const paintInksPattern = () => {
  return generatePath(paintInksPage);
};
export const paintInksWithSlug = "/paint-inks/:slug";
export const paintInksWithSlugPattern = (slug) => {
  return generatePath(paintInksWithSlug, slug);
};

//INDUSTRY DETAILS PAGE ROUTES END

export const termsAndConditionsPage = "/terms-and-conditions";
export const termsAndConditionsPagePattern = () => {
  return generatePath(termsAndConditionsPage);
};
export const privacyPage = "/privacy";
export const privacyPagePattern = () => {
  return generatePath(privacyPage);
};
