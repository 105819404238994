import { call, put, takeLatest } from "@redux-saga/core/effects";
// import {useParams,useLocation} from "react-router-dom"
import axios from "../axios/index";
import { BASE_URL, API_URL } from "../axios/config";
import { PRODUCT_DETAILS_FILE } from "./constant/index";
import { PRODUCT_DETAILS_DATA } from "../redux/constant/index";

async function productDetailApi(data) {
  return axios.request({
    method: "GET",
    url: BASE_URL + API_URL.PRODUCT_DETAILS,
    params: data,
  });
}

function* productDetailAction(action) {
  let { payload, reject } = action;

  try {
    let response = yield call(productDetailApi, payload);
    //  console.log("res",response);
    if (response.status === 200) {
      yield put({ type: PRODUCT_DETAILS_DATA, payload: response?.data?.data });
    }
  } catch (error) {
    if (reject) reject(error);
  }
}

export function* productDetailData() {
  yield takeLatest(PRODUCT_DETAILS_FILE, productDetailAction);
}
