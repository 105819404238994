import { TOUCH_DATA } from "../constant";

const initialState ={
    touchFileData:null,
};
export default function touchReducer(state = initialState, action) {
    switch (action.type) {
        case TOUCH_DATA:
            console.log( action?.payload?.status,"i am in touch reducer");
            return {
                ...state,
                touchFileData: action?.payload?.status
            };
        default:
            return state;
    }
}