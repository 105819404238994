import { PRODUCT_DETRAIL } from "../constant/index";

const initailState = {
    productDetail: null,
};

export default function singalProductDetailReducer(
  state = initailState,
  action
) {
  switch (action.type) {
    case PRODUCT_DETRAIL:
      return {
        ...state,
        productDetail: action?.payload,
      };
    default:
      return state;
  }
}
