import React from 'react'
import {Container} from "reactstrap"
import "./career.scss"

const Careers = () => {
  return (
    <>
   
        <Container>
             <div className='career_sec'>
                    <h2>Coming Soon...</h2>
             </div>
        </Container>
    </>
  )
}

export default Careers