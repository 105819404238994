import React from "react";
import "./rectangleGrid.scss";

const RectangleGrid = () => {
  return (
    <>
      <div className="row mx-0 cus-bg-relative">
        <div className="col-md-4 col-sm-4 col-4 ps-0">
          <div className="shape"></div>
        </div>
        <div className="col-md-8 col-sm-8 col-8 pe-0">
          <div className="double-shape"></div>
        </div>
      </div>
    </>
  );
};

export default RectangleGrid;
