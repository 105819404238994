import {  QUOTE_DATA } from "../constant/index.js"; 


const initialState = {
  quoteFileData: null,
};

export default function quoteReducer(state = initialState, action) {
  switch (action.type) {
    case QUOTE_DATA:
      console.log( action?.payload?.status,"i am in contact reducer");
      return {
        ...state,
        quoteFileData: action?.payload?.status
      };
    default:
      return state;
  }
}