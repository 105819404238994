import {FOOTER_INDESTRY_LIST_DATA} from "../constant/index"

const initalState={
    indestryListData:null,
}

export const footerIndestryReducer=(state=initalState,action)=>{
    switch (action.type) {
        case FOOTER_INDESTRY_LIST_DATA:
            return{
                ...state,
                indestryListData:action?.payload
            }
        default:
          return state
}

}
